import { inject } from "@angular/core"
import { CanActivateFn, Router } from "@angular/router"
import { AuthFacade } from "auth"
import { ConsumerData } from "auth/src/lib/domain/consumer-data.model"
import { isAllowedCountry } from "src/app/domain/lang"
import { DialogService } from "../dialogs/dialog.service"
import { of, skipWhile, switchMap, zip } from "rxjs"
import { LanguageService } from "../services/language.service"
import { toObservable } from "@angular/core/rxjs-interop"

export const locationGuard: CanActivateFn = () => {

    const authFacade = inject(AuthFacade)
    const router = inject(Router)
    const dialogService = inject(DialogService)
    const languageService = inject(LanguageService)

    const sources$ = [
        // authFacade.loaded$.pipe(
        //     skipWhile((isLoaded) => isLoaded === false),
        // ),
        toObservable(languageService.country).pipe(
            skipWhile((country) => country === undefined),
        )
    ]

    return zip(...sources$).pipe(
        switchMap((results: any) => {

            const [isLoaded, country] = results
            
            if(authFacade.isAuthenticated()) {
                const user = authFacade.profile()
                const lang = user?.csData?.collection['lang'] as ConsumerData

                /**
                 * - Check if user's country is allowed
                 */
                const isAllowed = isAllowedCountry(lang.value as string, user!.countryId)
                if (!isAllowed) {
                    dialogService.blockLocation().subscribe()
                    return of(false)
                }
                
                return of(true)
            }

            if(!languageService.isAllowedCountry()) {
                dialogService.blockLocation().subscribe()
                router.navigateByUrl('/');
                return of(false)
            }

            return of(true)

        })
    )
    
}